.top-bar {
  .menu-text {
    font-weight:bold;
    font-size:1.5rem;
    margin-left:1rem;
  }
  .logo {
    height:6.5rem;
    width:auto;
    margin-right: 2rem;
  }
  .menu {
    .last {
      margin-right:2rem;
    }
    a {
      margin-top:1rem;
      color: black;
    }
  }
  .icon {
    margin-top:-0.5rem !important;
    height:2rem;
    width:auto;
    margin-right: 0rem;
    margin-top:0rem;
  }
}
.footer {
  position:fixed;
  padding-top:1rem;
  color:white;
  bottom:0;
  height:5rem;
  width:100%;
  background-color:$primary-color;
  a {
    color:white;
    &:hover {
      color:gray;
    }
  }
}
